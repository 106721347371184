import React, { useEffect, useState } from "react";
import { Image, Button, Form, Row, Col } from "react-bootstrap";
import "./profile.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import DeleteAccount from "../Modals/DeleteAccount";
import ProfileEditDetails from "./ProfileEditDetails";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import {
  profileSuccess,
  updateProfileStart,
  verifyKycStart,
} from "../../store/slices/AdminSlice";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { useTranslation } from "react-multi-lang";
import BackupCodeModal from "../Helper/BackupCodeModal";
import { generateBackupCodesStart } from "../../store/slices/UserAccessSlice";
import OtpVerification from "./OtpVerification";
import iso from "iso-3166-1"

const ProfileIndex = () => {

  const t = useTranslation("profile.profile_index");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryCodeValue, setCountryCodeValue] = useState("56435267453");
  const [selectedOption, setSelectedOption] = useState(null);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [deleteaccountModal, setDeleteaccountModal] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const profile = useSelector((state) => state.admin.profile);
  const kycVerify = useSelector((state) => state.admin.kycVerify);
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const [codesModal, setCodesModal] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const [backupCodeModal, setBackupCodeModal] = useState(false);
  const generateBackupCodes = useSelector(state => state.access.generateBackupCodes);
  const [error, setError] = useState('');
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    if (
      !skipRender &&
      !kycVerify.loading &&
      Object.keys(kycVerify.data).length > 0
    ) {
      if (kycVerify.data.link.length > 0)
        window.open(kycVerify.data.link, "_blank");
    }
    setSkipRender(false);
  }, [kycVerify]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];

      if (!validTypes.includes(file.type)) {
        setError('Please upload a valid image file (PNG, JPEG, JPG).');
        return;
      }

      setError(''); // Clear error if file is valid
      handleUpdateProfilePicture(file);
    }
  };


  const handleUpdateProfilePicture = (image) => {
    image &&
      dispatch(
        updateProfileStart({
          title: profile.data.title,
          picture: image,
          first_name: profile.data.first_name,
          last_name: profile.data.last_name,
          email: profile.data.email,
          mobile_country_code: profile.data.mobile_country_code,
          mobile: profile.data.mobile,
        })
      );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateBackupCodes.loading &&
      Object.keys(generateBackupCodes.data).length > 0
    ) {
      setOtpVerification(false);
      setBackupCodeModal(generateBackupCodes.data)
    }
    setSkipRender(false);
  }, [generateBackupCodes]);

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding == 1) {
        setPercentage(0);
      } else if (profile.data.onboarding == 2) {
        setPercentage(33);
      }
      else if (profile.data.onboarding == 3) {
        setPercentage(66);
      } else {
        setPercentage(100);
      }
    }
  }, [profile]);

  return (
    <div className="full-body-card-wrapped">
      {profile.loading ? (
        <Row className="g-3 justify-content-center">
          <Col lg={12}>
            <Skeleton height={300} />
          </Col>
          <Col lg={6}>
            <Skeleton height={200} />
          </Col>
          <Col lg={6}>
            <Skeleton height={200} />
          </Col>
        </Row>
      ) : Object.keys(profile.data).length > 0 ? (
        <Row className="g-3 justify-content-center">
          <Col lg={12}>
            <div className="profile-full-frame-wrapped">
              <div className="profile-user-card-frame">
                <div className="profile-user-picture-sec align-items-center">
                  <div className="profile-account-user-img-sec">
                    {updateProfile.buttonDisable ? (
                      <CustomLazyLoad
                        className="profile-account-user-img"
                        src={window.location.origin + "/img/image-pulse.svg"}
                        type="image/png"
                      />
                    ) : (
                      <CustomLazyLoad
                        className="profile-account-user-img"
                        src={
                          profile.data.picture ||
                          window.location.origin + "/img/user-img.svg"
                        }
                        type="image/png"
                      />
                    )}
                    <label className="profile-account-user-img-edit-icon mb-3">
                      <input
                        type="file"
                        accept="image/png, image/jpeg,, image/jpg"
                        size="60"
                        disabled={updateProfile.buttonDisable}
                        onChange={handleFileChange}
                      />
                      <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 25 24"
                  >
                    <path
                      fill="#171717"
                      d="M21.5 22h-18c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75zM19.52 3.48c-1.94-1.94-3.84-1.99-5.83 0l-1.21 1.21c-.1.1-.14.26-.1.4a8.129 8.129 0 005.53 5.53.4.4 0 00.41-.1l1.2-1.21c.99-.98 1.47-1.93 1.47-2.89.01-.99-.47-1.95-1.47-2.94z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#171717"
                      d="M16.11 11.53c-.29-.14-.57-.28-.84-.44a8.8 8.8 0 01-.64-.42c-.17-.11-.37-.27-.56-.43a1.22 1.22 0 01-.17-.15c-.33-.28-.7-.64-1.03-1.04-.03-.02-.08-.09-.15-.18-.1-.12-.27-.32-.42-.55a5.49 5.49 0 01-.39-.59c-.16-.27-.3-.54-.44-.82-.14-.3-.25-.59-.35-.86l-6.28 6.28c-.13.13-.25.38-.28.55l-.54 3.83c-.1.68.09 1.32.51 1.75.36.35.86.54 1.4.54.12 0 .24-.01.36-.03l3.84-.54c.18-.03.43-.15.55-.28l6.28-6.28c-.28-.1-.55-.21-.85-.34z"
                    ></path>
                  </svg>
                    </label>
                  </div>
                  <h2>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {profile.data.title} {profile.data.first_name}{" "}
                    {profile.data.middle_name} {profile.data.last_name}
                  </h2>
                </div>
                {profile.data.onboarding != 4 ? (
                  <div className="profile-user-card-info">
                    <div className="profile-user-label">
                      <h3>{t("onboarding_status")}</h3>
                      <Link to="/user-onboarding">{t("complete")}</Link>
                    </div>
                    <div className="profile-complete-process">
                      <ProgressBar
                        completed={percentage}
                        height="8px"
                        bgColor="#30A75F"
                        labelAlignment="outside"
                        labelColor="#000000"
                        className="process-space-bar"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="space-line"></div>
              <div className="profile-basic-details-frame">
                <div className="profile-basic-details-card">
                  <h4>
                    {" "}
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 473.806 473.806"
                    >
                      <path
                        fill="#757575"
                        d="M374.456 293.506c-9.7-10.1-21.4-15.5-33.8-15.5-12.3 0-24.1 5.3-34.2 15.4l-31.6 31.5c-2.6-1.4-5.2-2.7-7.7-4-3.6-1.8-7-3.5-9.9-5.3-29.6-18.8-56.5-43.3-82.3-75-12.5-15.8-20.9-29.1-27-42.6 8.2-7.5 15.8-15.3 23.2-22.8 2.8-2.8 5.6-5.7 8.4-8.5 21-21 21-48.2 0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5-6-6.2-12.3-12.6-18.8-18.6-9.7-9.6-21.3-14.7-33.5-14.7s-24 5.1-34 14.7l-.2.2-34 34.3c-12.8 12.8-20.1 28.4-21.7 46.5-2.4 29.2 6.2 56.4 12.8 74.2 16.2 43.7 40.4 84.2 76.5 127.6 43.8 52.3 96.5 93.6 156.7 122.7 23 10.9 53.7 23.8 88 26 2.1.1 4.3.2 6.3.2 23.1 0 42.5-8.3 57.7-24.8.1-.2.3-.3.4-.5 5.2-6.3 11.2-12 17.5-18.1 4.3-4.1 8.7-8.4 13-12.9 9.9-10.3 15.1-22.3 15.1-34.6 0-12.4-5.3-24.3-15.4-34.3l-54.9-55.1zm35.8 105.3c-.1 0-.1.1 0 0-3.9 4.2-7.9 8-12.2 12.2-6.5 6.2-13.1 12.7-19.3 20-10.1 10.8-22 15.9-37.6 15.9-1.5 0-3.1 0-4.6-.1-29.7-1.9-57.3-13.5-78-23.4-56.6-27.4-106.3-66.3-147.6-115.6-34.1-41.1-56.9-79.1-72-119.9-9.3-24.9-12.7-44.3-11.2-62.6 1-11.7 5.5-21.4 13.8-29.7l34.1-34.1c4.9-4.6 10.1-7.1 15.2-7.1 6.3 0 11.4 3.8 14.6 7l.3.3c6.1 5.7 11.9 11.6 18 17.9 3.1 3.2 6.3 6.4 9.5 9.7l27.3 27.3c10.6 10.6 10.6 20.4 0 31-2.9 2.9-5.7 5.8-8.6 8.6-8.4 8.6-16.4 16.6-25.1 24.4-.2.2-.4.3-.5.5-8.6 8.6-7 17-5.2 22.7l.3.9c7.1 17.2 17.1 33.4 32.3 52.7l.1.1c27.6 34 56.7 60.5 88.8 80.8 4.1 2.6 8.3 4.7 12.3 6.7 3.6 1.8 7 3.5 9.9 5.3.4.2.8.5 1.2.7 3.4 1.7 6.6 2.5 9.9 2.5 8.3 0 13.5-5.2 15.2-6.9l34.2-34.2c3.4-3.4 8.8-7.5 15.1-7.5 6.2 0 11.3 3.9 14.4 7.3l.2.2 55.1 55.1c10.3 10.2 10.3 20.7.1 31.3zm-154.2-286.1c26.2 4.4 50 16.8 69 35.8s31.3 42.8 35.8 69c1.1 6.6 6.8 11.2 13.3 11.2.8 0 1.5-.1 2.3-.2 7.4-1.2 12.3-8.2 11.1-15.6-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3 3.7-15.6 11s3.5 14.4 10.9 15.6zm217.2 96.3c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2 3.7-15.5 11-1.2 7.4 3.7 14.3 11.1 15.6 46.6 7.9 89.1 30 122.9 63.7 33.8 33.8 55.8 76.3 63.7 122.9 1.1 6.6 6.8 11.2 13.3 11.2.8 0 1.5-.1 2.3-.2 7.3-1.1 12.3-8.1 11-15.4z"
                        data-original="#000000"
                      ></path>
                    </svg>{" "} */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#171717"
                        d="M17.621 10.75a.77.77 0 01-.77-.77c0-.37-.37-1.14-.99-1.81-.61-.65-1.28-1.03-1.84-1.03a.77.77 0 01-.77-.77c0-.42.35-.77.77-.77 1 0 2.05.54 2.97 1.51.86.91 1.41 2.04 1.41 2.86 0 .43-.35.78-.78.78zM21.23 10.75a.77.77 0 01-.77-.77c0-3.55-2.89-6.43-6.43-6.43a.77.77 0 01-.77-.77c0-.42.34-.78.76-.78C18.42 2 22 5.58 22 9.98c0 .42-.35.77-.77.77z"
                      ></path>
                      <path
                        fill="#171717"
                        d="M11.791 14.21l-3.27 3.27c-.36-.32-.71-.65-1.05-.99a28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41-.48-1.14-.72-2.23-.72-3.27 0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67.64-.63 1.34-.94 2.08-.94.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.36.35.71.69 1.06.99z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#171717"
                        d="M21.971 18.33a2.54 2.54 0 01-.25 1.09c-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.01 0-.02.01-.03.01-.59.24-1.23.37-1.92.37-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98c-.39-.29-.78-.58-1.15-.89l3.27-3.27c.28.21.53.37.74.48.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
                      ></path>
                    </svg>
                    {t("mobile_number")}{" "}
                  </h4>
                  <h5>
                    {" "}
                    +{profile.data.mobile_country_code}-{profile.data.mobile}{" "}
                  </h5>
                </div>
                <div className="profile-basic-details-card">
                  <h4>
                    {" "}
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#757575"
                        fillRule="evenodd"
                        d="M462.88 337.781c0 43.236-35.17 78.351-78.351 78.351H127.472c-43.181 0-78.352-35.116-78.352-78.351V174.219a77.802 77.802 0 0110.809-39.615l125.428 125.428c18.765 18.82 43.894 29.19 70.67 29.19 26.721 0 51.85-10.37 70.615-29.19L452.07 134.604a77.79 77.79 0 0110.809 39.615v163.562zM384.528 95.868H127.472c-17.832 0-34.293 6.035-47.461 16.076l126.69 126.745c13.114 13.058 30.616 20.301 49.326 20.301 18.655 0 36.158-7.243 49.271-20.301l126.69-126.745c-13.167-10.041-29.627-16.076-47.46-16.076zm0-30.232H127.472c-59.861 0-108.584 48.723-108.584 108.584v163.562c0 59.916 48.723 108.584 108.584 108.584h257.056c59.861 0 108.584-48.668 108.584-108.584V174.219c0-59.861-48.723-108.583-108.584-108.583z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>{" "} */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#171717"
                        d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#171717"
                        d="M12 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 01.93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79z"
                      ></path>
                    </svg>
                    {t("email")}
                  </h4>
                  <h5> {profile.data.email} </h5>
                </div>
                {profile.data.onboarding >= 2 ? 
                <React.Fragment>
                  {profile.data.nationality ? <div className="profile-basic-details-card">
                    <h4>
                      {" "}
                      <Image
                        className="nationality-icon"
                        src={window.location.origin + "/img/nationality.svg"}
                        type="image/png"
                      />
                      {t("nationality")}
                    </h4>
                    <h5>{iso.whereAlpha2(profile.data.nationality).country}</h5>
                  </div> : null}
                <div className="profile-basic-details-card">
                  <h4>
                    {" "}
                    <Image
                      className="nationality-icon"
                      src={window.location.origin + "/img/gender.svg"}
                      type="image/png"
                    />
                    {t("gender")}
                  </h4>
                  <h5> {profile.data.gender == "F" ? "Female" :
                    profile.data.gender == "M" ? "Male" : "Others"
                  } </h5>
                </div>
                  </React.Fragment> : null}
                  {profile.data.onboarding >= 3 ? <div className="profile-basic-details-card">
                  <h4>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#171717"
                        d="M20.41 6.96V9.8L7.4 19.34l-2.63-1.97c-.65-.49-1.18-1.54-1.18-2.35V6.96c0-1.12.86-2.36 1.91-2.75l5.47-2.05c.57-.21 1.49-.21 2.06 0l5.47 2.05c1.05.39 1.91 1.63 1.91 2.75z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#171717"
                        d="M20.41 11.17v3.85c0 .81-.53 1.86-1.18 2.35l-5.47 4.09c-.48.36-1.12.54-1.76.54-.64 0-1.28-.18-1.76-.54l-1.92-1.43 12.09-8.86z"
                      ></path>
                    </svg>
                    {profile.data.user_type == 1 ? t("kyc_verification_status") : t("kyb_verification_status")}
                  </h4>
                  {profile.data.user_type == 1 ? (
                    profile.data.mastercard_kyc_status == 3 ? (
                      <div className="verified-status-badge">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 21 21"
                        >
                          <path
                            fill="#3FBF5B"
                            d="M8.456 2.383a2.694 2.694 0 014.088 0 2.694 2.694 0 002.25.932 2.694 2.694 0 012.892 2.89c-.066.856.28 1.692.931 2.25a2.694 2.694 0 010 4.09 2.694 2.694 0 00-.931 2.25 2.694 2.694 0 01-2.892 2.89 2.694 2.694 0 00-2.25.932 2.694 2.694 0 01-4.088 0 2.694 2.694 0 00-2.25-.931 2.694 2.694 0 01-2.891-2.892 2.694 2.694 0 00-.932-2.25 2.694 2.694 0 010-4.088 2.694 2.694 0 00.932-2.25 2.694 2.694 0 012.89-2.891 2.694 2.694 0 002.25-.932z"
                          ></path>
                          <path
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M8 11.4L9.429 13 13 9"
                          ></path>
                        </svg>
                        {t("verified")}
                      </div>
                    ) : (
                      <Button
                        className="efi-primary-btn  w-100"
                        disabled={kycVerify.buttonDisable}
                        onClick={() => profile.data.user_type == 1 ? dispatch(verifyKycStart()) : navigate("/user-onboarding")}
                      >
                        {t("verify_kyc")}
                      </Button>
                    )
                  ) : profile.data.iban_kyc_status == 3 ? (
                    <div className="verified-status-badge">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 21 21"
                      >
                        <path
                          fill="#3FBF5B"
                          d="M8.456 2.383a2.694 2.694 0 014.088 0 2.694 2.694 0 002.25.932 2.694 2.694 0 012.892 2.89c-.066.856.28 1.692.931 2.25a2.694 2.694 0 010 4.09 2.694 2.694 0 00-.931 2.25 2.694 2.694 0 01-2.892 2.89 2.694 2.694 0 00-2.25.932 2.694 2.694 0 01-4.088 0 2.694 2.694 0 00-2.25-.931 2.694 2.694 0 01-2.891-2.892 2.694 2.694 0 00-.932-2.25 2.694 2.694 0 010-4.088 2.694 2.694 0 00.932-2.25 2.694 2.694 0 012.89-2.891 2.694 2.694 0 002.25-.932z"
                        ></path>
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M8 11.4L9.429 13 13 9"
                        ></path>
                      </svg>
                      {t("verified")}
                    </div>
                  ) : (
                    <Button
                      className="efi-primary-btn w-100"
                      disabled={kycVerify.buttonDisable}
                      onClick={() => profile.data.user_type == 1 ? dispatch(verifyKycStart()) : navigate("/user-onboarding")}
                    >
                      {t("verify_kyb")}
                    </Button>
                  )}
                </div> : null }
              </div>
              <div className="efi-bank-profile-action d-flex align-items-center justify-content-between">
                <Button
                  className="profile-action-btn mb-3"
                  onClick={() => setModalShow(true)}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 25 24"
                  >
                    <path
                      fill="#171717"
                      d="M21.5 22h-18c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h18c.41 0 .75.34.75.75s-.34.75-.75.75zM19.52 3.48c-1.94-1.94-3.84-1.99-5.83 0l-1.21 1.21c-.1.1-.14.26-.1.4a8.129 8.129 0 005.53 5.53.4.4 0 00.41-.1l1.2-1.21c.99-.98 1.47-1.93 1.47-2.89.01-.99-.47-1.95-1.47-2.94z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#171717"
                      d="M16.11 11.53c-.29-.14-.57-.28-.84-.44a8.8 8.8 0 01-.64-.42c-.17-.11-.37-.27-.56-.43a1.22 1.22 0 01-.17-.15c-.33-.28-.7-.64-1.03-1.04-.03-.02-.08-.09-.15-.18-.1-.12-.27-.32-.42-.55a5.49 5.49 0 01-.39-.59c-.16-.27-.3-.54-.44-.82-.14-.3-.25-.59-.35-.86l-6.28 6.28c-.13.13-.25.38-.28.55l-.54 3.83c-.1.68.09 1.32.51 1.75.36.35.86.54 1.4.54.12 0 .24-.01.36-.03l3.84-.54c.18-.03.43-.15.55-.28l6.28-6.28c-.28-.1-.55-.21-.85-.34z"
                    ></path>
                  </svg>
                  <span>{t("edit")}</span>{" "}
                </Button>
                <Button
                  className="profile-action-btn"
                  onClick={() => setOtpVerification(true)}
                >
                  {" "}
                  <span>{t("generate_codes")}</span>{" "}
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="efi-bank-profile-setting-card">
              <div className="efi-bank-profile-setting-icon">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <g data-name="1">
                    <path
                      d="M21.664 6.731a3.173 3.173 0 00-1.851-2.757l-5.571-2.282a5.878 5.878 0 00-4.484 0L4.187 3.973a3.175 3.175 0 00-1.852 2.758v4.386a12.245 12.245 0 008.954 11.537 2.685 2.685 0 001.423 0 12.245 12.245 0 008.953-11.537zm-9.351 14.477a1.184 1.184 0 01-.625 0 10.721 10.721 0 01-7.853-10.091V6.731a1.676 1.676 0 01.919-1.37l5.573-2.281a4.381 4.381 0 013.346 0l5.572 2.282a1.673 1.673 0 01.919 1.369v4.386a10.721 10.721 0 01-7.851 10.091z"
                      data-original="#000000"
                    ></path>
                    <path
                      fill="#1b3d86"
                      d="M14.75 10.5a2.75 2.75 0 10-3.5 2.633V15.5a.75.75 0 001.5 0v-2.367a2.745 2.745 0 002-2.633zM12 11.75a1.25 1.25 0 111.25-1.25A1.252 1.252 0 0112 11.75z"
                      data-original="#1fc759"
                    ></path>
                  </g>
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#171717"
                    d="M10.96 2.11l-5.5 2.06c-1.05.4-1.91 1.64-1.91 2.77v8.1c0 .81.53 1.88 1.18 2.36l5.5 4.11c.97.73 2.56.73 3.53 0l5.5-4.11c.65-.49 1.18-1.55 1.18-2.36v-8.1c0-1.12-.86-2.37-1.91-2.76l-5.5-2.06c-.56-.22-1.5-.22-2.07-.01z"
                    opacity="0.4"
                  ></path>
                  <path
                    fill="#171717"
                    d="M14.5 10.5a2.5 2.5 0 00-5 0c0 1.12.74 2.05 1.75 2.37v2.63c0 .41.34.75.75.75s.75-.34.75-.75v-2.63a2.488 2.488 0 001.75-2.37z"
                  ></path>
                </svg>
              </div>
              <div className="efi-bank-profile-setting-info">
                <h3>{t("authentication")}</h3>
                <p>{t("enable_disable")}</p>
              </div>
              <div className="efi-bank-profile-setting-action">
                <Button
                  className="profile-action-btn"
                  onClick={() => setPasswordChangeModal(true)}
                >
                  {/* <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7897 14.9301C17.7297 16.9801 14.7797 17.6101 12.1897 16.8001L7.47966 21.5001C7.13966 21.8501 6.46966 22.0601 5.98966 21.9901L3.80966 21.6901C3.08966 21.5901 2.41966 20.9101 2.30966 20.1901L2.00966 18.0101C1.93966 17.5301 2.16966 16.8601 2.49966 16.5201L7.19966 11.8201C6.39966 9.22007 7.01966 6.27007 9.07966 4.22007C12.0297 1.27007 16.8197 1.27007 19.7797 4.22007C22.7397 7.17007 22.7397 11.9801 19.7897 14.9301Z"
                      stroke="#171717"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.88965 17.49L9.18965 19.79"
                      stroke="url(#paint0_linear_4183_1157)"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
                      stroke="url(#paint1_linear_4183_1157)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_4183_1157"
                        x1="6.88965"
                        y1="18.64"
                        x2="9.18965"
                        y2="18.64"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#1b3d86" />
                        <stop offset="1" stop-color="#0b68b8" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_4183_1157"
                        x1="13"
                        y1="9.5"
                        x2="16"
                        y2="9.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#1b3d86" />
                        <stop offset="1" stop-color="#0b68b8" />
                      </linearGradient>
                    </defs>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#171717"
                      d="M19.79 4.22c-2.96-2.95-7.76-2.95-10.7 0-2.07 2.05-2.69 5-1.89 7.6l-4.7 4.7c-.33.34-.56 1.01-.49 1.49l.3 2.18c.11.72.78 1.4 1.5 1.5l2.18.3c.48.07 1.15-.15 1.49-.5l.82-.82c.2-.19.2-.51 0-.71l-1.94-1.94a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l1.95 1.95c.19.19.51.19.7 0l2.12-2.11c2.59.81 5.54.18 7.6-1.87 2.95-2.95 2.95-7.76 0-10.71zM14.5 12a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
                      opacity="0.4"
                    ></path>
                    <path fill="#171717" d="M14.5 12a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                  </svg>
                  <span>{t("change_password")}</span>{" "}
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="efi-bank-profile-setting-card">
              <div className="efi-bank-profile-setting-icon">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2a5 5 0 100 10 5 5 0 000-10zM9 7a3 3 0 116 0 3 3 0 01-6 0z"
                    clipRule="evenodd"
                    data-original="#000000"
                    fill="#1b3d86"
                  ></path>
                  <path
                    d="M6 21a6 6 0 016-6 1 1 0 100-2 8 8 0 00-8 8 1 1 0 102 0z"
                    data-original="#000000"
                  ></path>
                  <path
                    fill="#fa1228"
                    fillRule="evenodd"
                    d="M19.707 15.293a1 1 0 010 1.414L18.414 18l1.293 1.293a1 1 0 01-1.414 1.414L17 19.414l-1.293 1.293a1 1 0 01-1.414-1.414L15.586 18l-1.293-1.293a1 1 0 011.414-1.414L17 16.586l1.293-1.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                    data-original="#fa1228"
                  ></path>
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#171717"
                    d="M21.09 21.5c0 .28-.22.5-.5.5H3.41c-.28 0-.5-.22-.5-.5 0-4.14 4.08-7.5 9.09-7.5 1.03 0 2.03.14 2.95.41-.59.7-.95 1.61-.95 2.59 0 .75.21 1.46.58 2.06.2.34.46.65.76.91.7.64 1.63 1.03 2.66 1.03 1.12 0 2.13-.46 2.85-1.2.16.54.24 1.11.24 1.7z"
                    opacity="0.4"
                  ></path>
                  <path
                    fill="#171717"
                    d="M21.88 16.04c-.1-.39-.26-.78-.48-1.13-.15-.26-.35-.51-.57-.74a3.988 3.988 0 00-2.62-1.16c-1.09-.07-2.2.33-3.04 1.16a4.007 4.007 0 000 5.66c.53.53 1.18.88 1.87 1.04.38.1.78.14 1.18.11.95-.04 1.88-.42 2.61-1.15a3.98 3.98 0 001.05-3.79zM19.6 18.6c-.29.29-.77.29-1.06 0l-.55-.55-.53.53c-.29.29-.77.29-1.06 0a.754.754 0 010-1.06l.53-.53-.51-.5a.767.767 0 010-1.07c.3-.29.77-.29 1.07 0l.5.51.53-.53c.29-.29.76-.29 1.06 0 .29.29.29.77 0 1.06l-.53.53.55.55c.29.29.29.77 0 1.06zM12 12a5 5 0 100-10 5 5 0 000 10z"
                  ></path>
                </svg>
              </div>
              <div className="efi-bank-profile-setting-info">
                <h3>{t("delete_account")}</h3>
                <p>{t("enable_disable")}</p>
              </div>
              <div className="efi-bank-profile-setting-action">
                <Button
                  className="profile-action-btn"
                  onClick={() => setDeleteaccountModal(true)}
                >
                  {" "}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 24 24"
                  >
                    <g fillRule="evenodd" clipRule="evenodd">
                      <path
                        fill="#ff4a4a"
                        d="M9.425 10.254a.75.75 0 01.821.671l.5 5a.75.75 0 11-1.492.15l-.5-5a.75.75 0 01.671-.821zm5.15 0a.75.75 0 01.671.82l-.5 5a.75.75 0 11-1.492-.149l.5-5a.75.75 0 01.82-.671z"
                        data-original="#999999"
                      ></path>
                      <path
                        fill="#1a1a1a"
                        d="M10.124 1.25h3.381c.217 0 .405 0 .583.028a2.25 2.25 0 011.641 1.183c.083.16.143.339.211.544l.112.335.029.085a1.25 1.25 0 001.233.825h3a.75.75 0 110 1.5h-17a.75.75 0 010-1.5h3.09a1.25 1.25 0 001.173-.91l.112-.335c.068-.205.128-.384.211-.544a2.25 2.25 0 011.64-1.183c.179-.028.367-.028.584-.028zm-1.301 3a2.757 2.757 0 00.175-.428l.1-.3c.09-.273.112-.328.132-.368a.75.75 0 01.547-.395c.045-.007.104-.009.393-.009h3.29c.288 0 .347.002.392.01a.75.75 0 01.547.394c.02.04.041.095.133.369l.1.3.038.113c.04.108.085.213.137.314zM5.117 7.752a.75.75 0 01.798.698l.46 6.9c.09 1.347.154 2.285.294 2.99.137.685.327 1.047.6 1.303.274.256.648.422 1.34.512.713.093 1.654.095 3.004.095h.774c1.35 0 2.29-.002 3.004-.095.692-.09 1.066-.256 1.34-.512.273-.256.463-.618.6-1.303.14-.705.204-1.643.294-2.99l.46-6.9a.75.75 0 111.497.1l-.464 6.952c-.085 1.282-.154 2.318-.316 3.132-.169.845-.455 1.551-1.047 2.104-.591.554-1.315.793-2.17.904-.822.108-1.86.108-3.146.108h-.878c-1.286 0-2.324 0-3.146-.107-.855-.112-1.579-.351-2.17-.905-.592-.553-.878-1.26-1.047-2.104-.162-.814-.23-1.85-.316-3.132L4.418 8.55a.75.75 0 01.699-.798z"
                        data-original="#222222"
                      ></path>
                    </g>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#171717"
                      d="M21.07 5.23c-1.61-.16-3.22-.28-4.84-.37v-.01l-.22-1.3c-.15-.92-.37-2.3-2.71-2.3h-2.62c-2.33 0-2.55 1.32-2.71 2.29l-.21 1.28c-.93.06-1.86.12-2.79.21l-2.04.2c-.42.04-.72.41-.68.82.04.41.4.71.82.67l2.04-.2c5.24-.52 10.52-.32 15.82.21h.08c.38 0 .71-.29.75-.68a.766.766 0 00-.69-.82z"
                    ></path>
                    <path
                      fill="#171717"
                      d="M19.23 8.14c-.24-.25-.57-.39-.91-.39H5.68c-.34 0-.68.14-.91.39-.23.25-.36.59-.34.94l.62 10.26c.11 1.52.25 3.42 3.74 3.42h6.42c3.49 0 3.63-1.89 3.74-3.42l.62-10.25c.02-.36-.11-.7-.34-.95z"
                      opacity="0.399"
                    ></path>
                    <path
                      fill="#171717"
                      fillRule="evenodd"
                      d="M9.58 17a.75.75 0 01.75-.75h3.33a.75.75 0 010 1.5h-3.33a.75.75 0 01-.75-.75zM8.75 13a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>{t("delete_account")}</span>{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {passwordChangeModal ? (
        <ChangePasswordModal
          show={passwordChangeModal}
          onHide={() => setPasswordChangeModal(false)}
        />
      ) : null}
      {deleteaccountModal ? (
        <DeleteAccount
          show={deleteaccountModal}
          onHide={() => setDeleteaccountModal(false)}
        />
      ) : null}
      {modalShow ? (
        <ProfileEditDetails
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : null}
      {otpVerification ? (
        <OtpVerification
          show={otpVerification}
          onHide={() => setOtpVerification(false)}
        />
      ) : null}
      {backupCodeModal ? (
        <BackupCodeModal
          show={backupCodeModal}
          onHide={() => setBackupCodeModal(false)}
        />
      ) : null}
    </div>
  );
};

export default ProfileIndex;
