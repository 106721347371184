import React, { useState } from "react";
import { Navbar, Container, Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import useLogout from "../../hooks/useLogout";

const CollapseSidebar = (props) => {
  const { logout } = useLogout();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <>
      <div className={`admin-ui-sidebar admin-ui-collapse-sidebar`}>
        {/* <div className="admin-collapse-btn">
          <Button className="collapse-close-btn" onMouseOver={() => dispatch(toggleSidebar())}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              data-name="Layer 1"
              viewBox="0 0 512 512"
            >
              <path d="M121.373 457.373L322.745 256 121.373 54.627a32 32 0 0145.254-45.254l224 224a32 32 0 010 45.254l-224 224a32 32 0 01-45.254-45.254z"></path>
            </svg>
          </Button>
        </div> */}
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-logo"
                src={window.location.origin + "/img/logo-white.png"}
              />
            </Link>
            <Button
              className="offcanvas-close"
              onClick={() => dispatch(toggleSidebar())}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#f1f1f1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#656565"
                  d="M10.28 20.25H17c2.76 0 5-2.24 5-5v-6.5c0-2.76-2.24-5-5-5h-6.72c-1.41 0-2.75.59-3.7 1.64L3.05 9.27a4.053 4.053 0 000 5.46l3.53 3.88a4.978 4.978 0 003.7 1.64z"
                ></path>
                <path
                  fill="#171717"
                  d="M14.59 12l1.94-1.94c.29-.29.29-.77 0-1.06a.754.754 0 00-1.06 0l-1.94 1.94L11.59 9a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06L12.47 12l-1.94 1.94c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l1.94-1.94L15.47 15c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06L14.59 12z"
                ></path>
              </svg>
            </Button>
          </div>
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled">
              {/* <li>
                <Link to="/dashboard" className={`${location.pathname.includes(
                  "dashboard"
                ) && "active"
                  }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M22 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23zM10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M10.5 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23H4.23c-1.59 0-2.23.64-2.23 2.23v4.04C2 21.36 2.64 22 4.23 22h4.04c1.59 0 2.23-.64 2.23-2.23z"
                      opacity="0.4"
                    ></path>
                  </svg>
                  <span>DashBoard</span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/account"
                  className={`my-first-step ${
                    location.pathname == "/account" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#fff"
                      d="M10.13 10.13c-.86 0-1.558.697-1.558 1.558v2.338c0 .86.698 1.558 1.559 1.558h.018c.977 0 1.815-.051 2.515-.207.709-.157 1.323-.432 1.803-.911.479-.48.754-1.094.911-1.803.072-.322.119-.648.15-.977.08-.857-.64-1.556-1.501-1.556H10.13zM0 10.148V8.571c0-.86.698-1.558 1.558-1.558h3.896c.861 0 1.559.698 1.559 1.558v5.455c0 .86-.698 1.558-1.559 1.558h-.018c-.977 0-1.815-.051-2.515-.207-.709-.157-1.323-.432-1.803-.911-.479-.48-.754-1.094-.911-1.803C.05 11.963 0 11.125 0 10.148zM15.585 7.013c0 .86-.698 1.558-1.558 1.558H10.13c-.861 0-1.559-.697-1.559-1.558V1.558C8.572.698 9.27 0 10.131 0h.018c.977 0 1.815.051 2.515.207.709.157 1.323.432 1.803.911.479.48.754 1.094.911 1.803.156.7.207 1.538.207 2.515v1.577zM7.013 1.558C7.013.698 6.316 0 5.455 0h-.019C4.46 0 3.622.051 2.922.207c-.709.157-1.324.432-1.803.911-.48.48-.754 1.094-.911 1.803a7.924 7.924 0 00-.15.977c-.08.857.64 1.556 1.501 1.556h3.896c.86 0 1.558-.697 1.558-1.558V1.558z"
                    ></path>
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>
              {/* {profile.data.user_type == 2 ? ( */}
                <li>
                  <Link
                    to="/virtual-account"
                    className={`my-first-step ${
                      location.pathname.includes("virtual-account") && "active"
                    }`}
                  >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 16 17"
                  >
                    <path
                      fill="#fff"
                      d="M8 .456c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm4.856 12.82a3.783 3.783 0 00-3.403-2.133H6.547c-1.497 0-2.79.87-3.403 2.132a6.823 6.823 0 01-1.99-4.819A6.853 6.853 0 018 1.611a6.853 6.853 0 016.845 6.845c0 1.878-.76 3.581-1.989 4.82z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M8 9.994a3.076 3.076 0 100-6.152 3.076 3.076 0 000 6.152z"
                    ></path>
                  </svg>
                    <span>Account</span>
                  </Link>
                </li>
                <li>
                <Link
                  to="/send-money"
                  className={`my-first-step ${location.pathname.includes("send-money") && "active"
                    }`}
                >
                 <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 15 16"
                  >
                    <path
                      fill="#fff"
                      d="M14.018.652c.151-.148.074-.387-.123-.309L.418 5.703a.664.664 0 00-.005 1.231l4.691 1.91c.246.1.527.045.716-.14L14.018.651zM6.346 9.117a.665.665 0 00-.148.73c.667 1.609 1.311 3.228 1.968 4.84a.664.664 0 001.232-.004l5.36-13.477c.062-.157-.124-.228-.244-.11L6.346 9.117z"
                    ></path>
                  </svg>
                  <span>Send Money</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/currency-conversion"
                  className={`my-first-step ${location.pathname.includes("currency-conversion") && "active"
                    }`}
                >
                 <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 15 16"
                  >
                    <path
                      fill="#fff"
                      d="M14.188 1.838a.314.314 0 00-.344.068l-1.31 1.31A7.316 7.316 0 007.249.973 7.403 7.403 0 00.09 6.523a.315.315 0 00.305.394h2.006c.132 0 .25-.083.296-.207.587-1.605 2.046-2.83 3.716-3.12.275-.048.555-.072.832-.072 1.328 0 2.567.531 3.491 1.496l-1.33 1.33a.316.316 0 00.223.537h4.438a.315.315 0 00.315-.315V2.128a.316.316 0 00-.194-.29zM13.987 9.843H11.98a.315.315 0 00-.296.207c-.587 1.605-2.046 2.83-3.716 3.12a4.888 4.888 0 01-.833.072 4.795 4.795 0 01-3.49-1.496l1.33-1.33a.315.315 0 00-.224-.537H.315a.315.315 0 00-.315.315v4.437a.315.315 0 00.538.223l1.31-1.31a7.316 7.316 0 005.285 2.243 7.403 7.403 0 007.158-5.55.316.316 0 00-.304-.394z"
                    ></path>
                  </svg>
                  <span>Currency Conversion</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/balance-statement"
                  className={`my-first-step ${location.pathname.includes("balance-statement") && "active"
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      fill="#fff"
                      d="M11.357 12.015a1.984 1.984 0 01-1.41-.589 2.018 2.018 0 01-.583-1.42V4.649c0-.178.07-.348.195-.474a.661.661 0 01.47-.196h1.992c.352 0 .69.141.939.393.249.25.389.591.389.947v4.687c0 .533-.21 1.044-.584 1.42-.373.377-.88.589-1.409.589zm-.665-6.697v4.688c0 .178.07.348.195.473a.661.661 0 00.94 0 .672.672 0 00.194-.473V5.318h-1.329z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M12.68 9.335a.667.667 0 00-.667.667.668.668 0 01-1.334 0V1.995A1.335 1.335 0 009.344.659h-8.01A1.335 1.335 0 000 1.994v8.676a3.337 3.337 0 003.337 3.337h6.674a3.337 3.337 0 003.337-3.337v-.668a.668.668 0 00-.667-.667zM3.338 10.67a.668.668 0 110-1.336.668.668 0 010 1.336zm0-2.67a.667.667 0 110-1.335.667.667 0 010 1.335zm0-2.67a.667.667 0 110-1.334.667.667 0 010 1.335zm4.005 5.34H5.339a.667.667 0 110-1.335h2.003a.667.667 0 010 1.335zm0-2.67H5.339a.667.667 0 010-1.335h2.003a.667.667 0 010 1.335zm0-2.67H5.339a.667.667 0 110-1.334h2.003a.667.667 0 110 1.335z"
                    ></path>
                  </svg>
                  <span>Balance & Statement</span>
                </Link>
              </li>
              {/* ) : null} */}
              {/* {profile.data.mastercard_service == 1 ? (
                <li>
                  <Link
                    to="/card-details"
                    className={`my-second-step ${
                      location.pathname.includes("card-details") && "active"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="none"
                      viewBox="0 0 25 24"
                    >
                      <path
                        fill="#656565"
                        d="M22.03 7.54V9h-20V7.54c0-2.29 1.86-4.14 4.15-4.14h11.7c2.29 0 4.15 1.85 4.15 4.14z"
                      ></path>
                      <path
                        fill="#656565"
                        fillRule="evenodd"
                        d="M22.03 9v7.46c0 2.29-1.86 4.14-4.15 4.14H6.18c-2.29 0-4.15-1.85-4.15-4.14V9h20zm-14 8.25h-2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2c.41 0 .75.34.75.75s-.34.75-.75.75zm2.5 0h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-4c-.41 0-.75.34-.75.75s.34.75.75.75z"
                        clipRule="evenodd"
                        opacity="0.4"
                      ></path>
                    </svg>
                    <span>Manage Cards</span>
                  </Link>
                </li>
              ) : null}
              <li>
                <Link
                  to="/transactions"
                  className={`my-third-step ${
                    location.pathname == "/transactions" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="32"
                    fill="none"
                    viewBox="0 0 24 32"
                  >
                    <g fill="#656565">
                      <path d="M7.81.318a1.044 1.044 0 01.738.304l6.23 6.25a1.037 1.037 0 01.3.738c.001.135-.024.269-.074.394a1.04 1.04 0 01-.97.641l-3.125-.009-.028 9.347a1.037 1.037 0 01-1.045 1.035l-4.166-.012a1.043 1.043 0 01-1.038-1.042l.028-9.347-3.124-.01a1.044 1.044 0 01-.958-.647 1.036 1.036 0 01.234-1.13L7.08.617c.194-.19.456-.298.73-.299z"></path>
                      <path
                        d="M12.976 13.835l-.028 9.347-3.124-.01a1.044 1.044 0 00-.745 1.774l6.23 6.25a1.044 1.044 0 00.739.303 1.044 1.044 0 00.74-.299l6.268-6.212a1.036 1.036 0 00.304-.736.87.87 0 00-.082-.395 1.04 1.04 0 00-.956-.647l-3.125-.01.028-9.346a1.037 1.037 0 00-1.038-1.042l-4.166-.012a1.043 1.043 0 00-1.045 1.035z"
                        opacity="0.4"
                      ></path>
                    </g>
                  </svg>
                  <span>Transaction History</span>
                </Link>
              </li> */}
              {/* <li>
                <Link to="/contact-details" className={`${location.pathname.includes(
                  "contact-details"
                ) && "active"
                  }`}>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-address-book"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z" /><path d="M10 16h6" /><path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M4 8h3" /><path d="M4 12h3" /><path d="M4 16h3" /></svg>
                  <span>Contact Details</span>
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/recipients"
                  className={`my-fourth-step ${
                    location.pathname.includes("recipients") && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M9 2C6.38 2 4.25 4.13 4.25 6.75c0 2.57 2.01 4.65 4.63 4.74.08-.01.16-.01.22 0h.07a4.738 4.738 0 004.58-4.74C13.75 4.13 11.62 2 9 2z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M14.08 14.15c-2.79-1.86-7.34-1.86-10.15 0-1.27.85-1.97 2-1.97 3.23s.7 2.37 1.96 3.21C5.32 21.53 7.16 22 9 22c1.84 0 3.68-.47 5.08-1.41 1.26-.85 1.96-1.99 1.96-3.23-.01-1.23-.7-2.37-1.96-3.21z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M19.99 7.34c.16 1.94-1.22 3.64-3.13 3.87h-.05c-.06 0-.12 0-.17.02-.97.05-1.86-.26-2.53-.83 1.03-.92 1.62-2.3 1.5-3.8a4.64 4.64 0 00-.77-2.18 3.592 3.592 0 015.15 2.92z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M21.99 16.59c-.08.97-.7 1.81-1.74 2.38-1 .55-2.26.81-3.51.78.72-.65 1.14-1.46 1.22-2.32.1-1.24-.49-2.43-1.67-3.38-.67-.53-1.45-.95-2.3-1.26 2.21-.64 4.99-.21 6.7 1.17.92.74 1.39 1.67 1.3 2.63z"
                    ></path>
                  </svg>
                  <span>Recipients List</span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/beneficiary"
                  className={`my-fourth-step ${
                    location.pathname == "/beneficiary" && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="none"
                    viewBox="0 0 16 13"
                  >
                    <path
                      fill="#fff"
                      d="M5.437 6.393A2.765 2.765 0 008.21 3.636 2.765 2.765 0 005.437.88a2.765 2.765 0 00-2.772 2.757 2.765 2.765 0 002.772 2.757zM13.276 5.251a2.24 2.24 0 11-4.481 0 2.24 2.24 0 014.481 0zM10.597 9.798C9.96 9.11 9.17 8.551 8.558 8.178a6.352 6.352 0 00-3.12-.805c-1.5 0-2.938.51-3.998 1.418a4.791 4.791 0 00-1.365 1.89c-.315.806.408 1.532 1.27 1.532H9.53c.074 0 .147-.005.218-.015h1.958c.014-.872-.448-1.685-1.108-2.4z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M10.898 7.863c-.487 0-.967.061-1.426.179a9.622 9.622 0 011.573 1.34c.707.764 1.287 1.728 1.272 2.815h1.637c.467 0 .97-.1 1.318-.378.182-.146.33-.348.38-.608.051-.256 0-.518-.121-.769-.268-.557-.661-.949-1.11-1.332-.945-.804-2.213-1.247-3.523-1.247z"
                    ></path>
                  </svg>
                  <span>Beneficiary</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/quote"
                  className={`${
                    location.pathname.includes("quote") && "active"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#656565"
                      d="M22 8.5a6.5 6.5 0 01-7.02 6.48 6.509 6.509 0 00-5.96-5.96A6.5 6.5 0 1122 8.5z"
                    ></path>
                    <path
                      fill="#656565"
                      d="M15 15.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                      opacity="0.4"
                    ></path>
                    <path
                      fill="#656565"
                      d="M7.62 14.62L8.5 13l.88 1.62 1.62.88-1.62.88L8.5 18l-.88-1.62L6 15.5l1.62-.88z"
                    ></path>
                  </svg>
                  <span>Quote</span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/beneficiary-transactions"
                  className={`${
                    location.pathname == "/beneficiary-transactions" && "active"
                  }`}
                >
                   <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 16 18"
                  >
                    <path
                      fill="#fff"
                      d="M6.418 5.023a.4.4 0 01-.282-.684l4.01-4.02a.8.8 0 011.133 0l4.01 4.016a.4.4 0 01-.282.684h-2.038v5.219a.802.802 0 01-.8.802H9.26a.802.802 0 01-.802-.802V5.023H6.418zM.401 12.549a.4.4 0 00-.282.684l4.01 4.016a.8.8 0 001.133 0l4.01-4.016a.4.4 0 00-.282-.684H6.952V7.33a.802.802 0 00-.801-.802H3.243a.802.802 0 00-.8.802v5.22H.4z"
                    ></path>
                  </svg>
                  <span>Transactions</span>
                </Link>
              </li>
              {/* {profile.data.user_type == 2 ? (
                <li>
                  <Link
                    to="/business-user-list"
                    className={`my-fourth-step ${
                      location.pathname.includes("business-user-list") &&
                      "active"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#656565"
                        d="M17.53 7.77a.739.739 0 00-.21 0 2.874 2.874 0 01-2.78-2.88C14.54 3.3 15.83 2 17.43 2c1.59 0 2.89 1.29 2.89 2.89a2.89 2.89 0 01-2.79 2.88zM20.79 14.7c-1.12.75-2.69 1.03-4.14.84.38-.82.58-1.73.59-2.69 0-1-.22-1.95-.64-2.78 1.48-.2 3.05.08 4.18.83 1.58 1.04 1.58 2.75.01 3.8zM6.44 7.77c.07-.01.14-.01.21 0a2.874 2.874 0 002.78-2.88C9.43 3.3 8.14 2 6.54 2 4.95 2 3.65 3.29 3.65 4.89a2.89 2.89 0 002.79 2.88zM6.55 12.85c0 .97.21 1.89.59 2.72-1.41.15-2.88-.15-3.96-.86-1.58-1.05-1.58-2.76 0-3.81 1.07-.72 2.58-1.01 4-.85-.41.84-.63 1.79-.63 2.8z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#656565"
                        d="M12.12 15.87c-.08-.01-.17-.01-.26 0a3.425 3.425 0 01-3.31-3.43c0-1.9 1.53-3.44 3.44-3.44 1.9 0 3.44 1.54 3.44 3.44 0 1.86-1.46 3.37-3.31 3.43zM8.87 17.94c-1.51 1.01-1.51 2.67 0 3.67 1.72 1.15 4.54 1.15 6.26 0 1.51-1.01 1.51-2.67 0-3.67-1.71-1.15-4.53-1.15-6.26 0z"
                      ></path>
                    </svg>
                    <span>Business Info</span>
                  </Link>
                </li>
              ) : null} */}
            </ul>
            <ul className="admin-ui-sidebar-list list-unstyled">
              <li>
                <Link
                  to="/settings"
                  className={`${
                    location.pathname.includes("settings") && "active"
                  }`}
                >
                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 14 16"
                    >
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M13.335 9.094a1.555 1.555 0 01-.777-1.343c0-.553.297-1.068.776-1.344.49-.282.694-.868.485-1.393a7.358 7.358 0 00-1.061-1.843 1.12 1.12 0 00-1.451-.278 1.555 1.555 0 01-1.554.002A1.553 1.553 0 018.98 1.55 1.12 1.12 0 008.014.432a7.378 7.378 0 00-2.128.002c-.56.082-.965.551-.963 1.117 0 .553-.297 1.068-.775 1.344a1.556 1.556 0 01-1.553-.001 1.12 1.12 0 00-1.451.278A7.457 7.457 0 00.083 5.01a1.119 1.119 0 00.483 1.396c.48.276.778.791.778 1.344 0 .552-.298 1.067-.776 1.343-.49.282-.695.868-.485 1.393.263.664.62 1.284 1.06 1.843.352.445.961.562 1.452.278a1.555 1.555 0 011.553-.002c.478.276.775.792.775 1.346a1.12 1.12 0 00.965 1.117 7.377 7.377 0 002.127-.002 1.12 1.12 0 00.965-1.116 1.552 1.552 0 01.773-1.345 1.556 1.556 0 011.553.002c.491.284 1.1.167 1.452-.279a7.515 7.515 0 001.06-1.839 1.118 1.118 0 00-.483-1.396zM8.853 8.85a2.177 2.177 0 01-1.334 1.024 2.18 2.18 0 01-1.666-.22 2.197 2.197 0 113-.804z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  <span>Settings</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapseSidebar;
